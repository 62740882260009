.header-logo {
    width: 5rem;
}

.login-button {
    border: 2px solid $primary;
    font-size: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
}

.login-button:hover .filter-green {
    filter: brightness(0);
}

.faq-icon {
    width: 25px;
}
