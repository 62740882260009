.modal-container-dialog {
    width: 1100px;
    max-width: 90vw;
}

.modal-container-header {
    border-bottom: 0px;
    padding-bottom: 0px;
}

.modal-container-content {
    border-radius: 4px;
}

.modal-container-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-examination-title {
    font-weight: 400;
    font-size: 48px;
    line-height: 116.7%;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 70px;
}

.modal-container-examination-summary {
    padding-bottom: 60px;
    text-align: justify;
    width: 100%;
    overflow-wrap: break-word;
}

#modal-examination-summary {
    text-align: left;
}

.modal-examination-subtitle {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    text-align: left;
}

.modal-user-icon {
    margin-bottom: 10px;
}

.modal-user-image {
    height: 7rem;
}

.modal-user-button {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 16px;
    border-radius: 0;
    margin: 1rem 0rem;
    background-color: $secondary;
    color: #fff;
}

.modal-user-button-text {
    font-size: 12px;
    line-height: 266%;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;
}

.modal-user-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 133.4%;
    display: flex;
    align-items: center;
    text-align: center;
    padding-bottom: 4px;
}

.modal-container-user-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
}

.modal-user-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
}

.modal-user-summary {
    margin-bottom: 1rem;
}

%loading-skeleton {
    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    background-color: #eee;
    border-color: #eee;

    &::placeholder {
        color: transparent;
    }
}

.loading-skeleton {
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;
    @extend %loading-skeleton;
}

.modal-na-padding {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

@media (max-width: 992px) {
    .modal-container-title {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 0.5rem;
    }

    .modal-body {
        padding: 0px 30px;
    }

    .modal-container-examination-summary {
        padding-bottom: 20px;
    }

    .modal-container-user-summary {
        padding-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .modal-examination-title {
        font-size: 30px;
    }
}

@media (max-width: 580px) {
    .modal-container-dialog {
        width: auto;
        max-width: none;
    }
}

@keyframes loading-skeleton {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}
