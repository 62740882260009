.contact-button {
    display: flex;
    width: fit-content;
    height: fit-content;
    gap: 10px;
    padding: 14px 32px;
    border-radius: 16px;
    align-items: center;
    border: 2px solid #ffffff;
}

.contact-button-text {
    color: white;
    white-space: nowrap;
    font-weight: 500;
}

.contact-button:hover .contact-button-text {
    color: $primary;
}

.contact-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    min-height: 7rem;
    background-image: url('../../images/contact-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3% 10%;
}

.contact-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.contact-content > div {
    flex: 1;
    display: flex;
    justify-content: center;
}

.socials-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.icons {
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    z-index: 10000;
}

.icons i {
    cursor: pointer;
}

.icons .li {
    padding: 4px 8px;
}

.icons i#fb {
    color: #3b5998;
}

.icons i#tw {
    color: #1da1f2;
}

.icons i#in {
    color: #0077b5;
}

.icons i#wh {
    color: #128c7e;
}

.icons i::before {
    font-size: 18px;
}

.like-and-share-icon {
    transition: 250ms;
    width: 45px;
    height: 45px;
}

.like-and-share-button {
    border: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show {
    visibility: visible;
}

@media (max-width: 500px) {
    .contact-content {
        flex-direction: column;
    }

    .contact-button {
        margin-bottom: 5%;
    }
}
