//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import 'variables';
@import 'bootstrap';
@import 'fonts';

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

.simple-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    white-space: nowrap;
}

.primary-color {
    color: $primary;
}

.primary-color:hover {
    color: #4cb5ab;
}

.disabled-color {
    color: #c7c7cd;
}

.button-container {
    display: flex;
    width: fit-content;
    height: fit-content;
    gap: 10px;
    padding: 14px 32px;
    border-radius: 16px;
    align-items: center;
}

.button-text {
    color: white;
    white-space: nowrap;
}

.page {
    min-height: unquote('calc(100vh - 128.97px)');
}

@media (max-width: 767px) {
    .button-container {
        padding: 10px 24px;
    }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import 'components/nav-bar';
@import 'components/headline';
@import 'components/user-card';
@import 'components/examination-card';
@import 'components/about-us';
@import 'components/create-account';
@import 'components/modal';
@import 'components/contact';
@import 'components/footer';
@import 'components/terms-of-service';
@import 'components/faq';
