.about-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding: 2rem 0rem;
}

.about-us-img-container {
    display: flex;
    gap: 3rem;
    align-items: center;
}

.about-us-img {
    height: 12rem;
}

.about-us-img-title {
    height: 15rem;
    transform: scale(1.2);
}

.about-us-title-container {
    background: #00675b;
    transform: rotate(-90deg);
    display: flex;
    padding: 16px 40px;
    width: fit-content;
}

.about-us-title {
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;

    display: flex;
    align-items: center;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    white-space: nowrap;
    color: #ffffff;
}

.about-us-subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.15px;
    gap: 1.5rem;
    text-align: left;
}

@media (max-width: 1024px) {
    .about-us-container {
        flex-direction: column;
        gap: 2rem;
    }

    .about-us-subtitle {
        margin: 0 2rem;
    }

    .about-us-img-title {
        transform: rotate(90deg);
        margin: -50%;
    }

    .about-us-img-container {
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .about-us-container {
        flex-direction: column;
    }
}
