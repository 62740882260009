.user-card {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 16px;
    transition: all .2s ease-in-out;

    text-decoration: none;
    border: 1px solid #F2EFE9;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.user-card:hover {
    transform: scale(1.05);
}

.user-card-image {
    height: 7rem;
}

.user-card-button {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 16px;
    border-radius: 0;
}

.user-card-button-text {
    font-size: 12px;
    line-height: 266%;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;
}

.user-card-button-text {
    font-size: 12px;
    line-height: 266%;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;
}

.user-card-title {
    font-size: 24px;
    line-height: 133.4%;

    display: flex;
    align-items: center;
    text-align: center;

    color: $dark;
}

.user-card-subtitle {
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: $dark;
}