.examinations-title {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;

    border-bottom: 1px solid #ffb74d;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.examinations-title-text {
    font-weight: 400;
    font-size: 48px;
    line-height: 116.7%;
    display: flex;
    align-items: center;
    color: $dark;
}

.examination-card {
    text-decoration: auto;
    color: $dark;
}

.examination-box {
    height: 325px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px;
    gap: 10px;
    transition: all 0.2s ease-in-out;
    border: 1px solid #52c7b8;
    box-shadow: inset 0px 0px 5px #52c7b8;
    border-radius: 16px;
}

.examination-box:hover {
    background-color: #009688;
    border: 0.5012px solid #009688;
    color: #fff;
    cursor: pointer;
    transition: 250ms;
}

.examination-box:hover > div > svg > g path {
    stroke: #fff;
}

.examination-box:hover > div > svg > path:only-child {
    fill: #fff;
}

.examination-box:hover > div > svg > path:first-child {
    stroke: #fff;
}

.examination-box:hover > div > svg > path:nth-child(2) {
    stroke: #fff;
}

.examination-box-icon {
    height: 2.5rem;
}

.examination-box-title {
    font-weight: 400;
    font-size: 2rem;
    line-height: 123.5%;
    /* or 42px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
}

.examination-box-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 175%;
    /* identical to box height, or 28px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
}

@media (max-width: 1200px) {
    .examination-box-title {
        font-size: 26px;
    }
}

@media (max-width: 1024px) {
    .examination-card {
        min-width: 340px;
    }
}

@media (max-width: 767px) {
    .examination-cards-container {
        justify-content: center;
    }
}

@media (max-width: 580px) {
    .examinations-title-text {
        font-size: 40px;
    }
}

@media (max-width: 480px) {
    .examinations-title-text {
        font-size: 30px;
    }
}
