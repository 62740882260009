.footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    height: 50px;
    background: #212121;
    color: #fff;
    box-sizing: border-box;
}

.footer-side {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.footer-text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-size: 16px;
    display: flex;
    gap: 0.5rem;
}

.footer-link {
    display: flex;
    font-weight: 500;
}

.information-icon {
    display: none;
}

.hipaa-icon {
    width: 30px;
}

@media (max-width: 767px) {
    .footer-container {
        padding: 0 20px;
    }

    .footer-link {
        display: none;
    }

    .information-icon {
        display: flex;
    }

    .footer-text {
        font-size: 12px;
    }

    .hipaa-icon {
        width: 25px;
    }
}
