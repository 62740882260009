#headline-image {
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.headline-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    max-width: 90rem;
    background: url('../../images/headline-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
}

.headline-title {
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    font-size: 60px;
    line-height: 120%;
    letter-spacing: -0.5px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
}

.headline-subtitle {
    color: #ffffff;
    text-align: center;

    font-weight: 700;
    font-size: 26px;
    line-height: 133.4%;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
}

@media (max-width: 1200px) {
    .headline-title {
        font-weight: 700;
        font-size: 50px;
    }

    .headline-subtitle {
        font-size: 24px;
    }
}

@media (max-width: 1024px) {
    .headline-title {
        font-size: 42px;
    }

    .headline-subtitle {
        font-size: 20px;
    }
}

@media (max-width: 767px) {
    .headline-title {
        font-size: 28px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .headline-title {
        font-size: 24px;
    }

    .headline-subtitle {
        font-size: 16px;
    }
}
