.create-account-container {
    max-width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin: 3rem 0rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
        0px 1px 5px rgba(0, 0, 0, 0.2);
    padding: 1rem;
}

.create-account-img {
    max-width: 80vw;
}

.create-account-container-text {
    max-width: 80vw;
    width: 34rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
}

.create-account-title {
    font-weight: 400;
    font-size: 48px;
    line-height: 116.7%;
}

.create-account-subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    text-align: left;
}

@media (max-width: 1200px) {
    .create-account-container {
        gap: 2rem;
    }

    .create-account-title {
        font-size: 40px;
    }
}

@media (max-width: 1024px) {
    .create-account-container {
        flex-direction: column;
        gap: 1rem;
    }

    .create-account-container-text {
        align-items: center;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .create-account-title {
        font-size: 30px;
    }

    .create-account-subtitle {
        font-size: 16px;
    }
}
